import { configureTranslations } from '@whiz-cart/ui-shared/translate';
import orderBy from 'lodash/orderBy';
import { createTranslator, FlatKeys, FlattenDict, ReactCreateTranslatorOptions } from 'schummar-translate/react';
import en from './translations/languages/en';
import meta from './translations/languages/generated/meta.json';

export type Dict = FlattenDict<typeof en>;
export type TranslationKeys = FlatKeys<typeof en>;

const dicts: Record<string, () => Promise<Partial<Dict>>> = Object.fromEntries(
    Object.entries(import.meta.glob('./translations/languages/generated/*.ts', { import: 'default' })).map(([key, value]) => {
        const locale = key.match(/([^/]*)\.ts$/)![1]!;

        return [
            locale,
            async () => dictOverrides[locale] ?? (value() as Promise<Partial<Dict>>),
        ];
    }),
);

let dictOverrides: Partial<Record<string, Dict>> = {};

export function configureDicts(newDicts: typeof dictOverrides) {
    dictOverrides = newDicts;
}

const warningsSeen = new Set<string>();

export const translatorConfig = {
    sourceLocale: 'en',
    sourceDictionary: en,
    dicts,
    fallbackLocale: ['de', 'en'],
    ignoreMissingArgs: true,

    warn: (locale, id) => {
        const key = `${locale}:${id}`;
        if (warningsSeen.has(key)) return;
        warningsSeen.add(key);
        console.warn('Missing translation:', locale, key);
    },

    dateTimeFormatOptions: {
        dateStyle: 'medium',
        timeStyle: 'medium',
    },
} satisfies ReactCreateTranslatorOptions<{}>;

export const { t, useTranslator, getTranslator, clearDicts } = createTranslator(translatorConfig);
configureTranslations({ t, useTranslator, getTranslator, clearDicts });

const AdditionalProperties: { [code: string]: { region?: string } } = {
    de: { region: 'DE' },
    en: { region: 'GB' },
    'en-CA': {},
    'fr-CA': {},
    fr: { region: 'FR' },
    nb: { region: 'NO' },
    ro: { region: 'RO' },
};

export const LanguageMeta = {
    ...meta,
    languages: orderBy(
        meta.languages.map((language) => {
            const code = [language.language, language.region].filter(Boolean).join('-');
            return {
                code,
                ...language,
                ...AdditionalProperties[code],
            };
        }),
        (language) => {
            const index = Object.keys(AdditionalProperties).indexOf(language.code);
            return index >= 0 ? index : Infinity;
        },
    ),
};

export const browserLocale = dicts[navigator.language] ? navigator.language : navigator.language.slice(0, 2);
