import { ParsedDate } from '@whiz-cart/node-shared/models/parsed/parsedDate';
import { z } from 'zod';

export enum UserType {
    Global = 0,
    Store = 1,
    Tenant = 2,
}

const ScopeType = z.literal('global').or(z.literal('store')).or(z.literal('tenant'));
export type ScopeType = z.infer<typeof ScopeType>;

export const ScopeRoles = z.object({
    scopeType: ScopeType,
    scope: z.string().nullable(),
    roles: z.string().array().nullish(),
});
export type ScopeRoles = z.infer<typeof ScopeRoles>;

export const User = z.object({
    accountGuid: z.string(),
    userName: z.string(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    lastActiveOn: ParsedDate.nullish(),
    lastActiveIn: z.string().nullish(),
    accountType: z.nativeEnum(UserType).nullish(),
    scopeRoles: z.record(ScopeRoles).nullish(),
});
export type User = z.infer<typeof User>;

export const UserTableEntry = User.extend({
    firstName: z.string(),
    lastName: z.string(),
    lastActiveOn: ParsedDate,
    accountType: z.nativeEnum(UserType),
    scopeRoles: z.record(
        z.object({
            roles: z.string().array(),
            scopeType: ScopeType.optional(),
        }),
    ),
    isSubscribed: z.array(z.string()).optional(),
    needsSubscription: z.boolean().optional(),
});

export type UserTableEntry = z.infer<typeof UserTableEntry>;

const TenantRoles = z.object({
    tenant: z.string().nullish(),
    roles: z.array(z.string()).nullish(),
});
export type TenantRoles = z.infer<typeof TenantRoles>;

const StoreRoles = z.object({
    storeGuid: z.string().nullish(),
    tenant: z.string().nullish(),
    roles: z.array(z.string()).nullish(),
});
export type StoreRoles = z.infer<typeof StoreRoles>;

export const Subscription = z.object({
    storeGuid: z.string().nullish(),
    topic: z.string().nullish(),
    account: z.object({
        accountGuid: z.string().nullish(),
        userName: z.string().nullish(),
        firstName: z.string().nullish(),
        lastName: z.string().nullish(),
        salt: z.string().nullish(),
        hash: z.string().nullish(),
        createdOn: z.string().nullish(),
        createdBy: z.string().nullish(),
        modifiedOn: z.string().nullish(),
        modifiedBy: z.string().nullish(),
        deletedOn: z.string().nullish(),
        deletedBy: z.string().nullish(),
        globalRoles: z.array(z.string()).nullish(),
        storeRoles: StoreRoles.array().nullish(),
        tenantRoles: TenantRoles.array().nullish(),
        refreshTokens: z
            .array(
                z.object({
                    token: z.string().nullish(),
                    deviceId: z.string().nullish(),
                    validTo: z.string().nullish(),
                    revokedOn: z.string().nullish(),
                }),
            )
            .nullish(),
        lastActiveOn: z.string().nullish(),
        lastActiveIn: z.string().nullish(),
        accountType: z.number().nullish(),
        emailVerifiedOn: z.string().nullish(),
        emailVerificationToken: z.string().nullish(),
        isEmailVerified: z.boolean().nullish(),
    }),
});
export type Subscription = z.infer<typeof Subscription>;
